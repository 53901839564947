import * as React from "react"
import Box from "../elements/Box.js"
import CardConsultoria from "../icons/CardConsultoria.js"
import CardCursos from "../icons/CardCursos.js"
import CardWorkshop from "../icons/CardWorkshop.js"

import ContainerWithBg from "../helpers/ContainerWithBg.js";

let c = 'col-12 col-sm-10 col-md-8 col-lg-4 d-flex';

export default function ConsulCursoWorkshopBoxSection({
  consultoria = "Com nossa experiência de mais de oito anos em diagnóstico, desenvolvemos uma metodologia de planejamento e estratégia, focada na prevenção de crise e fortalecimento da reputação, que trará resultados efetivos para sua empresa.",
  cursos = "Estamos desenvolvendo uma nova e inédita trilha de conhecimento focada em Planejamento, Estratégia e Gestão.  Os módulos isolados se encontram com carrinho fechado e em breve, divulgaremos novidades. Saiba mais sobre as próximas turmas ao vivo.",
  workshops = "Em um formato dinâmico, levamos nossos workshops para sua empresa, adaptando os conteúdos e os cases de acordo com as necessidades e área de atuação do cliente."
}) {
  return (
    <ContainerWithBg classBgName="bg-color-purple-3 mt--2r pb-4r" className="container-xg">
      <div className="container-fluid z1">
        <div className="row justify-content-center">
          <Box
            title="Projetos"
            content={consultoria}
            toPath="/projetos"
            toLabel="Conheça"
            boxClass={c}
            icon={<CardConsultoria />}
          />
          <Box
            title="Cursos"
            content={cursos}
            toPath="/cursos"
            toLabel="Saiba mais"
            boxClass={c}
            icon={<CardCursos />}
          />
          <Box
            title="Workshops"
            content={workshops}
            toPath="/workshops"
            toLabel="Leve para sua empresa"
            boxClass={c}
            icon={<CardWorkshop />}
          />
        </div>
      </div>
    </ContainerWithBg>
  )
}