import * as React from "react"

export default function CardWorkshop({width = 64, height = 64, fill = '#FFCB1F', ...props}) {
  return (
    <svg style={{ width: width, height: height }} viewBox="0 0 62 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6766 56.8235H25.7355V58.8823H23.6766V56.8235Z" fill="#FA7D00"/>
      <path d="M19.559 56.8235H21.6178V58.8823H19.559V56.8235Z" fill="#FA7D00"/>
      <path d="M15.4413 56.8235H17.5001V58.8823H15.4413V56.8235Z" fill="#FA7D00"/>
      <path d="M60.7357 6.38197H56.9302L51.013 2.43721C50.8441 2.32462 50.6446 2.2643 50.4415 2.2643H45.2944C45.022 2.2643 44.7591 2.37237 44.5666 2.56589L43.2532 3.8793C40.8299 2.12406 38.1579 0.815673 35.2681 0.0345617C34.96 -0.0468668 34.6278 0.0169691 34.3734 0.211493C34.1201 0.406017 33.9708 0.707605 33.9708 1.0288V24.9115C33.9708 25.4805 34.4322 25.9409 35.0002 25.9409H52.5004C53.0688 25.9409 53.5298 25.4805 53.5298 24.9115V23.8821C53.5298 23.2005 53.4896 22.5119 53.4313 21.8233H60.7357C61.3042 21.8233 61.7651 21.3628 61.7651 20.7938V7.41139C61.7651 6.84189 61.3042 6.38197 60.7357 6.38197ZM51.4709 23.8821H36.0297V2.41459C38.4509 3.22636 40.7022 4.44025 42.7405 6.03364C44.5505 7.42949 46.1293 9.09526 47.4347 10.9852C48.0112 11.8141 48.534 12.6852 48.9974 13.5879H45.2944C44.1961 13.5879 43.1627 14.0162 42.3846 14.7953C41.606 15.5734 41.1767 16.6078 41.1767 17.7056C41.1767 19.976 43.0235 21.8233 45.2944 21.8233H51.3754C51.4393 22.5119 51.4709 23.2015 51.4709 23.8821ZM59.7063 19.7644H45.2944C44.1589 19.7644 43.2356 18.8411 43.2356 17.7056C43.2356 17.1577 43.4497 16.641 43.8398 16.2509C44.2303 15.8609 44.746 15.6467 45.2944 15.6467H52.5004C52.9165 15.6467 53.292 15.3954 53.4519 15.0114C53.6112 14.6264 53.5227 14.1835 53.2282 13.8895L49.0562 9.71803C47.867 8.01557 46.4641 6.48904 44.8933 5.1515L45.7207 4.32314H50.1299L56.047 8.2679C56.2159 8.37999 56.4155 8.44081 56.618 8.44081H59.7063V19.7644Z" fill="#FA7D00"/>
      <path d="M6.17651 61.9706C6.17651 62.5396 6.63794 63 7.20593 63H30.8826C31.4511 63 31.912 62.5396 31.912 61.9706V58.8823H42.2062C45.0446 58.8823 47.3533 56.5732 47.3533 53.7352V45.4999H53.5298C53.8696 45.4999 54.1867 45.333 54.3782 45.053C54.5697 44.7731 54.613 44.4167 54.4903 44.0995L49.4121 30.8965V29.0292C49.4121 28.4597 48.9512 27.9998 48.3827 27.9998H31.912V5.14245C31.912 4.677 31.6003 4.26935 31.1505 4.14771C29.0454 3.58073 26.8775 3.29372 24.706 3.29372C11.0838 3.29372 0 14.3771 0 27.9998V34.7061C0 40.4709 2.18953 45.898 6.17651 50.0383V61.9706ZM2.05884 34.7061V27.9998C2.05884 15.5115 12.2183 5.35255 24.706 5.35255C26.4357 5.35255 28.1627 5.5511 29.8531 5.94517V29.0292C29.8531 29.5982 30.3146 30.0586 30.8826 30.0586H47.3533V31.088C47.3533 31.2147 47.3759 31.3388 47.4211 31.4575L52.0309 43.441H46.3238C45.7559 43.441 45.2944 43.901 45.2944 44.4705V49.5135C42.9486 49.0345 41.1767 46.9566 41.1767 44.4705H39.1179C39.1179 48.093 41.8081 51.0913 45.2944 51.594V53.7352C45.2944 55.4377 43.9091 56.8235 42.2062 56.8235H27.7943V58.8823H29.8531V60.9412H8.23535V49.6176C8.23535 49.3436 8.12728 49.0822 7.93376 48.8897C4.14582 45.1003 2.05884 40.0643 2.05884 34.7061Z" fill="#FA7D00"/>
      <path d="M25.7355 27.9998H27.7943V30.0586H25.7355V27.9998Z" fill="#FA7D00"/>
      <path d="M25.7355 23.8821H27.7943V25.9409H25.7355V23.8821Z" fill="#FA7D00"/>
      <path d="M21.6178 23.8821H23.6766V25.9409H21.6178V23.8821Z" fill="#FA7D00"/>
      <path d="M25.7355 19.7644H27.7943V21.8233H25.7355V19.7644Z" fill="#FA7D00"/>
      <path d="M25.7355 15.6467H27.7943V17.7056H25.7355V15.6467Z" fill="#FA7D00"/>
      <path d="M21.6178 15.6467H23.6766V17.7056H21.6178V15.6467Z" fill="#FA7D00"/>
      <path d="M25.7355 11.5291H27.7943V13.5879H25.7355V11.5291Z" fill="#FA7D00"/>
      <path d="M25.7355 7.41139H27.7943V9.47023H25.7355V7.41139Z" fill="#FA7D00"/>
      <path d="M20.5884 7.41139H22.6472V9.47023H20.5884V7.41139Z" fill="#FA7D00"/>
      <path d="M25.7355 32.1174H27.7943V34.1763H25.7355V32.1174Z" fill="#FA7D00"/>
      <path d="M25.7355 36.2351H27.7943V38.294H25.7355V36.2351Z" fill="#FA7D00"/>
      <path d="M21.6178 32.1174H23.6766V34.1763H21.6178V32.1174Z" fill="#FA7D00"/>
      <path d="M29.8531 32.1174H31.912V34.1763H29.8531V32.1174Z" fill="#FA7D00"/>
      <path d="M33.9708 32.1174H36.0297V34.1763H33.9708V32.1174Z" fill="#FA7D00"/>
      <path d="M33.9708 36.2351H36.0297V38.294H33.9708V36.2351Z" fill="#FA7D00"/>
      <path d="M38.0885 32.1174H40.1473V34.1763H38.0885V32.1174Z" fill="#FA7D00"/>
      <path d="M42.2062 32.1174H44.265V34.1763H42.2062V32.1174Z" fill="#FA7D00"/>
      <path d="M42.2062 36.2351H44.265V38.294H42.2062V36.2351Z" fill="#FA7D00"/>
    </svg>
  )
}