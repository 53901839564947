import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import Container from '../common/helpers/Container.js';
import ContainerWithBg from '../common/helpers/ContainerWithBg.js';

import Button from '../common/components/Button.js';
import Divider from '../common/components/Divider.js';

import BlogPost from '../common/elements/BlogPost.js';
import Page from '../common/elements/Page.js';
import VideoPlayer from '../common/elements/VideoPlayer.js';

// import BlogText from "../common/icons/BlogText.js"
// import Trama from "../common/icons/Trama.js"

import ConsultoriaCursoWorkshopBoxSection from '../common/section/ConsulCursoWorkshopBoxSection.js';
import EmpresasParceiras from '../common/section/EmpresasParceiras.js';
import FormContatoImageSection from '../common/section/FormContatoImageSection.js';

export default function IndexPage({ data }) {
  const fields = data.wpPage.paginaHomeFields;
  return (
    <Page>
      <Helmet>
        <link rel="preload" as="image" href="../images/bg-hero-home.png"></link>
      </Helmet>
      <Seo post={data.wpPage} />

      <ContainerWithBg
        classBgName="bg-pattern bg-pattern-gradient bg-image-bg-hero-home py-8 py-lg-0"
        className="container-xg tac mh-40r ai-c jc-c p-4 feature-image dfc"
      >
        <h1 className="c-white z1">
          Conectando Pessoas e Estratégias ao <br /> Processo de Comunicação
        </h1>
      </ContainerWithBg>

      <ConsultoriaCursoWorkshopBoxSection
        consultoria={fields ? fields.comunicacaoPaginaHomeConsultoria : null}
        workshops={fields ? fields.comunicacaoPaginaHomeWorkshops : null}
        cursos={fields ? fields.comunicacaoPaginaHomeCursos : null}
      />

      <EmpresasParceiras btnLabel="Conheça nossos projetos" />

      <Container className="container-fluid">
        <div className="row">
          <div className="quem-somos col-md-6 bg-color-golden pt-3r p-r">
            <div className="mw-31r z1 p-r p-1r">
              <h4 className="h1 c-purple">Quem somos</h4>
              <p className="c-grey-1 mt-15r">
                Empresa especializada em Gestão Estratégica de Projetos, Planejamento de Comunicação Integrada (do Diagnóstico à Mensuração de Resultados) e Mapeamento de Processos e Riscos.
              </p>
              <h4 className="h1 c-purple mt-4r">Manifesto da Marca</h4>
              <p className="c-grey-1 mt-15r">
                Acreditamos que para fortalecer a reputação e potencializar
                resultados nos negócios, o Planejamento de Comunicação precisa
                conectar Pessoas (Cultura Organizacional) a Estratégias claras e
                Processos eficientes.
              </p>
              <VideoPlayer video="https://www.youtube.com/embed/VaxHQETH600" />
            </div>
            <Divider height={125}>
              <div className="bg-pattern bg-pattern-linear h-100"></div>
            </Divider>
          </div>

          <div className="col-md-6 bg-color-purple c-white p-r dfc ai-c jc-c p-0">
            <div className="col-size-560 tac py-8 px-4">
              <StaticImage
                alt="Differentiation"
                src="../images/differentiation.svg"
              />
              <h4 className="mb-1r h2 mt-6r tal">Diferenciais</h4>
              <p className="tal">
                Soluções completas de Comunicação Corporativa, a partir de um
                Diagnóstico 360º e posterior definição de Estratégias alinhadas
                a seu negócio e Cultura Organizacional. Em todo o processo de
                implementação do Plano de Comunicação Integrada, priorizamos
                ações e estratégias para fortalecimento da Reputação da Marca e
                Prevenção de Crises.
              </p>
            </div>
            <div className="pl-1r pr-1r bg-pattern bg-image-dot-grid"></div>
          </div>
        </div>
      </Container>

      <div className="blog-bgtext">
        <div className="container-xg">
          <h3 className="h2 tac c-purple mb-3r z1 p-r">
            {' '}
            Para ler e se inspirar
          </h3>
          <div className="container-fluid pb-4r">
            <div className="row justify-content-center">
              {data.allWpPost.nodes.map((node) => (
                <div
                  className="col-12 col-sm-10 col-md-8 col-lg-4 d-flex mb-5 mb-lg-0"
                  key={node.id}
                >
                  <BlogPost
                    tag={node.categories.nodes[0].name}
                    date={node.date}
                    toPath={node.link}
                    toPathTag={node.categories.nodes[0].link}
                    title={node.title}
                    excerpt={node.excerpt}
                    imageSrc={
                      node.featuredImage ? node.featuredImage.node : null
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ContainerWithBg
        classBgName="bg-color-reddish-purple"
        className="container-xl"
      >
        <div className="z1 container-fluid mt-4r mb-4r">
          <div className="row">
            <div className="col-12 col-md-6 order-2 order-md-1">
              <h2 className="h2 c-white mb-2r mt-1 mt-md-3">
                "Ouvi Dizer": Comunicação Integrada como antídoto para boatos
                organizacionais
              </h2>
              <p className="c-white mb-2r">
                O Guia Essencial para toda empresa que quer prevenir e gerenciar
                boatos, utilizando as técnicas da Comunicação Integrada.
              </p>
              <Button
                className="btn bg-color-golden dib"
                to="https://compartilheconhecimento.comunicacaointegrada.com.br/ouvi-dizer-livro"
                targetBlank
              >
                <p className="c-reddish-purple mb-0">Conheça nosso livro</p>
              </Button>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2 d-flex justify-content-end">
              <StaticImage
                src="../images/livro-ouvi-dizer.png"
                alt="Imagem do livro Ouvi Dizer: Comunicação Integrada como antídoto para boatos organizacionais"
                width={407}
                height={477}
              />
            </div>
          </div>
        </div>
      </ContainerWithBg>

      <ContainerWithBg classBgName="bg-color-purple" className="container-xl">
        <div className="z1 container-fluid mt-4r mb-4r">
          <div className="row">
            <div className="col-12 col-md-6 order-1 order-md-1">
              <StaticImage src="../images/gic/gic-mock.png" alt="GIC" />
            </div>
            <div className="col-12 col-md-6 order-2 order-md-2">
              <h2 className="h2 c-white mb-2r mt-1 mt-md-3">
                Grau de Integração da Comunicação® (GIC)
              </h2>
              <p className="mb-2r c-white">
                Uma Metodologia inédita para avaliar o quanto a Comunicação da
                sua empresa dialoga com as Estratégias de Negócio e possui papel
                relevante nos Resultados Corporativos, em médio e longo prazo.
              </p>
              <Button
                className="btn c-white outline dib invert"
                to="grau-de-integracao-da-comunicacao/"
                hasIcon
              >
                Avalie e receba Insights Estratégicos
              </Button>
            </div>
          </div>
        </div>
      </ContainerWithBg>

      {/* <ContainerWithBg classBgName="bg-image bg-image-portifolio" className="container-xl eq pt-1r pb-1r ai-c"> */}
      <ContainerWithBg
        classBgName="bg-image bg-image-portifolio"
        className="container-xl"
      >
        <div className="z1 container-fluid mt-4r mb-4r">
          <div className="row">
            <div className="col-12 col-md-6 order-2 order-md-1">
              <h2 className="h2 c-purple mb-2r mt-1 mt-md-3">
                Materiais Educativos
              </h2>
              <p className="c-gray-1 mb-2r">
                A partir da nossa expertise em Planejamento de Comunicação,
                Estratégia e Gestão de Projetos, desenvolvemos diversos
                materiais estratégicos para sua empresa.
              </p>
              <Button
                className="btn bg-color-purple-1 dib"
                to="materiais-educativos/"
              >
                Acesse nossos materiais
              </Button>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2">
              <StaticImage
                src="../images/img-materiais-educativos.png"
                alt="livros"
              />
            </div>
          </div>
        </div>
      </ContainerWithBg>

      <FormContatoImageSection />
    </Page>
  );
}

export const pageQuery = graphql`
  {
    allWpPost(sort: { fields: [date], order: DESC }, limit: 3) {
      nodes {
        id
        title
        excerpt
        link
        date(formatString: "d M y")
        categories {
          nodes {
            link
            name
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
    wpPage(databaseId: { eq: 19029 }) {
      id
      nodeType
      title
      uri
      paginaHomeFields {
        comunicacaoPaginaHomeConsultoria
        comunicacaoPaginaHomeCursos
        comunicacaoPaginaHomeWorkshops
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`;
