import React, { Component, Fragment } from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Play from "../icons/Play.js"

export default class VideoPlayer extends Component {
  state = {
    showLightbox: false,
  }

  componentDidMount = () => {
    window.addEventListener('keyup', this.handleKeyUp, false)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keyup', this.handleKeyUp, false)
  }

  handleClick = (e) => {
    e.preventDefault()
    this.setState({ showLightbox: !this.state.showLightbox })
  }

  closeModal = () => {
    this.setState({ showLightbox: false })
  }

  handleKeyUp = e => {
    e.preventDefault()
    const { keyCode } = e
    if (this.state.showLightbox) {
      if (keyCode === 27) {
        // Escape key
        this.setState({ showLightbox: false })
      }
    }
  }

  render() {
    const { showLightbox } = this.state
    const { video } = this.props

    return (
      <Fragment>
        <div className="ml-3r mr-3r mt-4r df ai-c jc-c p-r c-p" role="button" tabIndex={0}
          onClick={e => this.handleClick(e)}
          onKeyDown={e => this.handleClick(e)}
        >
          <div className="bg-color-purple rounder p-a h-100 w-100 player-bg"></div>
          <StaticImage src="../../images/manifesto-thumb.png" alt=""/>
          <Play className="p-r z1 p-a h-100 w-100" />
        </div>
        <div className="modal" visible={showLightbox.toString()} onKeyUp={e => this.handleKeyUp(e)} role="button" tabIndex={0}>
          <div className="modal-bg">
            <div className="modal-close" role="button" tabIndex={0}
              onClick={this.closeModal} 
              onKeyDown={this.closeModal}
            >
              x
            </div>            
            <div className="modal-content">
              { showLightbox &&
                <iframe src={video} title="Manifesto Comunicação Integrada" width="100%" height="100%" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              }
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

